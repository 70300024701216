<template>
  <v-dialog
    v-model="dialog"
    overlay-opacity=".8"
    persistent
    max-width="290"
    :width="width"
  >
    <v-card
      :height="height"
      class="containerStyle"
    >
      <v-container>
        <slot name="title" />
        <slot name="list" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    status: {
      type: Boolean,
      required: true,
    },
    /* eslint-disable */
    height: {
      type: Number,
      required: false,
    },
    width: {
      type: Number,
      required: false,
    },
  },

  computed: {
    // Dialog 狀態
    dialog: {
      get() {
        return this.status
      },
      set(newStatus) {
        this.$emit('update-dialog', newStatus)
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
