var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "overlay-opacity": ".8",
        persistent: "",
        "max-width": "290",
        width: _vm.width,
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "containerStyle", attrs: { height: _vm.height } },
        [_c("v-container", [_vm._t("title"), _vm._t("list")], 2)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }